export enum Feature {
  ActivityDetails = 'activity_details',
  AnalyzeDashboard = 'analyze_dashboard',
  AnalyzeTeamFilter = 'analyze_team_filter',
  ButtonGuideCard = 'button_guide_card',
  ExportGameResults = 'export_game_results',
  GameDetailsPotentialPayout = 'game_details_potential_payout',
  GamePayoutLeaderboardRewards = 'game_payouts_leaderboard_rewards',
  GuideStats = 'guide_stats',
  IncludeNTIDInReports = 'include_ntid_in_reports',
  IntegrationConnectionStatus = 'integration_connection_status',
  LocationInformation = 'location_information',
  ManageActivitiesTeamSales = 'manage_activities_team_sales',
  ManageTeamsIdentifiersDrawer = 'manage_teams_identifiers_drawer',
  NewGameDetailsMetricCard = 'new_game_details_metric_card',
  PerformanceDashboard = 'performance_dashboard',
  PerformanceTeamFilter = 'performance_team_filter',
  PlayerIdentifiers = 'player_identifiers',
  PlayerVsPlayerByLocation = 'player_vs_player_by_location',
  TeamAdmin = 'team_admin_role',
  UserVerification = 'user_activation_user_verification',
}
